<template>
	<div class="case-situation" v-loading="loadingFlagC1">
		<el-row>
			<el-col :span="7">
				<div v-loading="loadingFlagL1" class="content-box" style="margin-top: 0;">
					<h4 class="title-h4">最新立案</h4>
					<div v-show="chronicleEvents.length != 0" style="width: 100%;height: 270px;overflow: hidden;">
						<vue-seamless-scroll :data="chronicleEvents" :class-option="classOption" id="chronicleEventsContent">
							<ul class="chronicle-events-box">
								<li v-for="(item,index) in chronicleEvents" :key="index">
									<div class="content-box">{{item.companyName}}</div>
									<p class="time-box">{{item.caseDate}}</p>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
					<div v-show="chronicleEvents.length == 0" style="width: 100%;height: 270px;overflow: hidden;">
						<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 90px auto;">
					</div>
				</div>
				<div v-loading="loadingFlagL2" class="content-box">
					<h4 class="title-h4">行业分布</h4>
					<div id="echartsPieIndustry" style="height: 270px;"></div>
				</div>
				<div v-loading="loadingFlagL3" class="content-box">
					<h4 class="title-h4">涉案金额TOP10（万元）</h4>
					<div id="echartsBarMoney" style="height: 288px;overflow: hidden;"></div>
				</div>
			</el-col>
			<el-col :span="10">
				<div class="content-box" style="margin: 0px 10px 0px 10px;position: relative;">
					<h4 class="title-h4">地域分布</h4>
					<div style="overflow: hidden;">
						<div id="echartsMap" style="width: 100%;height: 594px;"></div>
						<p class="mapFox">
							<span class="mapClick" v-for="(item,index) in mapFoxArr" :key="index" ref="nameFox" :data-name="item.param" @click="mapClickHand(item,index)">{{item.name}}&nbsp;&nbsp;</span>
						</p>
						<span class="iconfont icon-quanping6 mapLookBig" title="全屏" @click="mapFullOpen"></span>
					</div>
				</div>
				<div v-loading="loadingFlagC2" class="content-box" style="margin: 10px 10px 0px 10px;">
					<h4 class="title-h4">案件数量变化趋势</h4>
					<div id="echartsLine" style="height: 288px;"></div>
				</div>
			</el-col>
			<el-col :span="7">
				<div class="content-box" style="margin-top: 0;">
					<h4 class="title-h4">集资类型热词</h4>
					<div id="echartsWordCloud" style="height: 270px;"></div>
				</div>
				<div v-loading="loadingFlagR2" class="content-box">
					<h4 class="title-h4">地域排行</h4>
					<div id="chartsBar" style="height: 270px;"></div>
				</div>
				<div v-loading="loadingFlagR3" class="content-box">
					<h4 class="title-h4">参与人数TOP10（人）</h4>
					<div id="echartsBarUsers" style="height: 288px;overflow: hidden;"></div>
				</div>
			</el-col>
		</el-row>
		<!-- 累计预警  地图  全屏 -->
		<el-dialog class="dialogVisibleScreenFull" :visible.sync="dialogVisible" :fullscreen="true" @close="mapFullClose">
			<div id="echartsMap_full" style="width: 100%;" :style="{ height: screenHeight + 'px' }"></div>
			<p class="mapFox">
				<span class="mapClick" v-for="(item,index) in mapFoxArr" :key="index" ref="nameFox" :data-name="item.param" @click="mapClickHandFull(item,index)">{{item.name}}&nbsp;&nbsp;</span>
			</p>
		</el-dialog>
	</div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getMapEname, echartsPieHuan, echartsMap2D, echartsLineD, echartsBarHeng, echartsGauge, echartsWordCloud } from "../common/js/chart.js"
export default {
	name: "case-situation",
	components: { vueSeamlessScroll },
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			loadingFlagC1: false,
			loadingFlagC2: false,
			loadingFlagL1: false,
			loadingFlagL2: false,
			loadingFlagL3: false,
			loadingFlagR2: false,
			loadingFlagR3: false,
			mapFoxArr: [{
				"name": "中国",
				"param": "中国"
			}],
			levelrisk: {
				levelG: 0,
				levelZ: 0,
				levelD: 0,
				levelW: 0,
			},
			chronicleEvents: [], //大事记
			dialogVisible: false,
			screenHeight: 0,
		}
	},
	created() {
		//根据 用户名  默认显示
		let proviceName_session = JSON.parse(sessionStorage.getItem("user_xjfj")).provinceName;
		let cityName_session = JSON.parse(sessionStorage.getItem("user_xjfj")).cityName;
		if (proviceName_session != "null" && cityName_session == "null") {
			this.mapFoxArr.push({
				"name": proviceName_session,
				"param": proviceName_session
			});
		} else if (proviceName_session != "null" && cityName_session != "null") {
			this.mapFoxArr.push({
				"name": proviceName_session,
				"param": proviceName_session
			});
			this.mapFoxArr.push({
				"name": cityName_session,
				"param": cityName_session
			});
		}
	},
	mounted() {
		this.getBistributionMap("echartsMap"); //地域分布   地图
	},
	computed: {
		//无缝滚动
		classOption() {
			return {
				direction: 2,
				singleWidth: this.chronicleEvents.length * 229,
				limitMoveNum: 4,
				waitTime: 0,
				step: 1,
				hoverStop: true
			}
		}
	},
	methods: {
		//加载地图  层级  下钻   返回   小屏
		mapClickHand(item, index) {
			this.getChartMap(item, index); //判断生成几级地图
		},
		//加载地图  层级  下钻   返回   全屏
		mapClickHandFull(item, index) {
			this.getChartMap(item, index); //判断生成几级地图
		},
		getChartMap(item, index) {
			if (index >= JSON.parse(sessionStorage.getItem("user_xjfj")).level) {
				if (index == 1) {
					this.mapFoxArr = [{
						"name": "中国",
						"param": "中国"
					}];
					this.mapFoxArr.push(item);
				} else if (index == 0) {
					this.mapFoxArr = [];
					this.mapFoxArr.push(item);
				}
				if (index != 2) {
					if (!this.dialogVisible) {
						this.getBistributionMap("echartsMap"); //地域分布   累计预警  小屏
					} else {
						this.getBistributionMap("echartsMap_full"); //地域分布   累计预警  全屏
					}
				}
			}
		},
		//加载地图  全屏  打开
		mapFullOpen() {
			this.dialogVisible = true;
			this.screenHeight = document.body.clientHeight - 30;
			this.getBistributionMap("echartsMap_full"); //地域分布   累计预警  全屏
		},
		//加载地图  全屏  关闭
		mapFullClose() {
			this.dialogVisible = false;
			this.getBistributionMap("echartsMap"); //地域分布   累计预警  小屏
		},
		//加载地图
		getBistributionMap(contanter) {
			this.loadingFlagC1 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/caseSituation/getCaseAreaCount', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagC1 = false; //loading 消失
				if (res.code == 200) {
					var data = res.data;
					let resultArr = [];
					for (var i = 0; i < data.length; i++) {
						resultArr.push({
							name: data[i].name,
							value: Number(data[i].count)
						})
					}
					//获取地图中文文字   转换   拼音名字
					let mapEname = getMapEname(getParam.name);
					echartsMap2D(contanter, mapEname, resultArr, this); //地域分布   地图
					let resultdata = [];
					for (let i = 0; i < data.length; i++) {
						resultdata.push({
							name: data[i].name,
							value: Number(data[i].count)
						})
					}
					echartsBarHeng("chartsBar", resultdata, 'yj'); //地域排名
					this.getAreaRiskAvg(); //最新立案
					this.getCaseHotWordCount(); //热词
					this.getCompanyIndustry(); //行业分布
					this.getCaseMoneyTop(); //涉案金额top10
					this.getCountByMonthAndRisk(); //变化趋势
					this.getCasePersonTop(); //涉案人数top10
				}
			})
		},
		//最新立案
		getAreaRiskAvg() {
			this.loadingFlagL1 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/caseSituation/getNewCaseList', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagL1 = false; //loading 消失
				if (res.data.length > 3) {
					let aa = res.data;
					let bb = aa.concat(res.data);
					let cc = bb.concat(res.data);
					let dd = cc.concat(res.data);
					let ee = dd.concat(res.data);
					let ff = ee.concat(res.data);
					let gg = ff.concat(res.data);
					this.chronicleEvents = gg;
				} else {
					this.chronicleEvents = res.data;
				}
				document.getElementById("chronicleEventsContent").firstElementChild.style.width = this.chronicleEvents.length * 229 + "px";
			})
		},
		//热词
		getCaseHotWordCount() {
			this.loadingFlagL2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/caseSituation/getCaseHotWordCount', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagL2 = false; //loading 消失
				if (res.code == 200) {
					var data = res.data;
					let resultdata = [];
					for (let i = 0; i < data.length; i++) {
						resultdata.push({
							value: Number(data[i].count),
							name: data[i].fundWay
						});
					}
					echartsWordCloud("echartsWordCloud", resultdata);
				}
			})
		},
		//行业分布
		getCompanyIndustry() {
			this.loadingFlagR2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/caseSituation/getCaseIndustryCount', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagR2 = false; //loading 消失
				if (res.code == 200) {
					let data = res.data;
					let legendData = [];
					let seriesData = [];
					for (let i = 0; i < data.length; i++) {
						seriesData.push({
							value: Number(data[i].count),
							name: data[i].industry
						});
						legendData.push(data[i].industry);
					}
					let resultdata = {
						legendData: legendData,
						seriesData: seriesData,
					}
					let colorList = ['#FF686F', '#FFB22E', '#269CF6', '#1DCC7F', '#40EED5', '#AFA3F5'];
					echartsPieHuan("echartsPieIndustry", resultdata, colorList);
				}
			})
		},
		//变化趋势
		getCountByMonthAndRisk() {
			this.loadingFlagC2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/caseSituation/getCaseDateCount', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagC2 = false; //loading 消失
				if (res.code == 200) {
					let resultdataX = [];
					let resultdataY = [];
					res.data.filter((item, index) => {
						resultdataX.push(item.dateTime);
						resultdataY.push(item.count);
					})
					echartsLineD("echartsLine", resultdataX, resultdataY);
				}
			})
		},
		//涉案人数top10
		getCasePersonTop() {
			this.loadingFlagR3 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/caseSituation/getCasePersonTop', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagR3 = false; //loading 消失
				let data = res.data;
				let resultdata = [];
				for (let i = 0; i < data.length; i++) {
					resultdata.push({
						name: data[i].companyName,
						value: Number(data[i].num)
					})
				}
				echartsBarHeng("echartsBarUsers", resultdata, 'yj'); //地域排名
			})
		},
		//涉案金额top10
		getCaseMoneyTop() {
			this.loadingFlagL3 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/caseSituation/getCaseMoneyTop', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagL3 = false; //loading 消失
				let data = res.data;
				let resultdata = [];
				for (let i = 0; i < data.length; i++) {
					resultdata.push({
						name: data[i].companyName,
						value: Number(data[i].money)
					})
				}
				echartsBarHeng("echartsBarMoney", resultdata, 'yj'); //地域排名
			})
		},

		//获取 省市 等  参数
		getParam_fn() {
			let proviceName = "",
				cityName = "",
				name = "";
			let nameFox = [];
			this.mapFoxArr.filter((item, index) => {
				if (item.name != null) {
					nameFox.push(item);
				}
			})
			if (nameFox.length == 2) {
				proviceName = nameFox[1].name;
				cityName = "";
				name = nameFox[1].name;
			} else if (nameFox.length == 3) {
				proviceName = nameFox[1].name;
				cityName = nameFox[2].name;
				name = nameFox[2].name;
			} else {
				name = "中国";
			}
			let param = {
				proviceName: proviceName,
				cityName: cityName,
				name: name
			}
			return param;
		},
		
		// true:数值型的，false：非数值型
		isRealNum(val) {
			// isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
			if (val === "" || val == null) {
				return false;
			}
			if (!isNaN(val)) {
				//对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
				//所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
